<template>
  <div class="container">
    <a-layout>
      <Header />
      <a-layout id="components-layout-demo-custom-trigger">
        <Sider />
        <a-layout class="section">
          <a-layout-content :style="{
              margin: '24px 16px',
              padding: '24px',
              marginTop: '24px',
              background: '#fff',
              minHeight: '280px',
            }">
            <div class="container">
              <div ref="box">
                <div ref="header">
                  <a-page-header style="border-bottom: 1px solid rgb(235, 237, 240)" title="用户管理">
                    <template slot="extra">
                      <a-button html-type="submit" style="margin-left: 16px" @click="getExport(1)" size="small">
                        导出
                      </a-button>
                    </template>
                  </a-page-header>
                </div>
                <div ref="form">
                  <a-form :form="form" layout="inline" @submit="handleSubmit" style="padding: 15px 0" ref="form">
                    <a-form-item label="用户昵称">
                      <a-input v-decorator="[
                          'nickname',
                          { rules: [{ required: false }] },
                        ]" />
                    </a-form-item>
                    <a-form-item label="用户手机号">
                      <a-input v-decorator="[
                          'mobile',
                          { rules: [{ required: false }] },
                        ]" />
                    </a-form-item>
                    <a-form-item label="京东推客ID">
                      <a-input v-decorator="[
                          'jd_id',
                          { rules: [{ required: false }] },
                        ]" />
                    </a-form-item>
                    <a-form-item label="淘宝推客ID">
                      <a-input v-decorator="[
                          'tb_id',
                          { rules: [{ required: false }] },
                        ]" />
                    </a-form-item>
                    <a-form-item>
                      <a-button type="primary" html-type="submit">
                        搜索
                      </a-button>
                    </a-form-item>
                  </a-form>
                </div>
                <a-table :columns="columns" :data-source="userList" :pagination="false"
                  :scroll="{ x: 1500, y: table_h }" :row-key="(record) => record.id" size="middle" ref="tableList">
                  <span slot="avatar" slot-scope="avatar">
                    <img :src="avatar" alt="" />
                  </span>
                  <span slot="grade" slot-scope="grade, num" @click="changeGrade(num)">
                    <span v-if="grade == 1">街头艺人</span>
                    <span v-if="grade == 2">当红明星</span>
                    <span v-if="grade == 3">翻红馆长</span>
                    <span v-if="grade == 4">运营商</span>
                    <a-icon type="edit" style="color: #1890ff; padding-left: 5px" />
                  </span>
                  <span slot="pid" slot-scope="pid">
                    {{ pid == 0 ? "--" : pid }}
                  </span>
                  <span slot="invite_code" slot-scope="invite_code, num" @click="changeInviteCode(num)">
                    <span>{{ invite_code }}</span>
                    <a-icon type="edit" style="color: #1890ff; padding-left: 5px" />
                  </span>
                  <span slot="invitation_enable" slot-scope="invitation_enable,nums">
                    <a-switch :checked="invitation_enable == 1" @change="changeSetinvitation(nums)" />
                  </span>
                  <span slot="jd_id" slot-scope="jd_id, num">
                    <span v-if="num.jd_id">
                      <span @click="changejdId(num)">{{ jd_id }}</span>
                      <a-icon type="edit" style="color: #1890ff; padding-left: 5px" @click="changejdId(num)" />
                    </span>
                    <a-button class="editable-add-btn" @click="changejdId(num)" size="small" v-else>
                      设置京东推客ID
                    </a-button>
                  </span>
                  <span slot="tb_id" slot-scope="tb_id, num">
                    <span v-if="num.tb_id">
                      <span @click="changetbId(num)">{{ tb_id }}</span>
                      <a-icon @click="changetbId(num)" type="edit" style="color: #1890ff; padding-left: 5px" />
                    </span>
                    <a-button class="editable-add-btn" @click="changetbId(num)" size="small" v-else>
                      设置淘宝推客ID
                    </a-button>
                  </span>
                  <span slot="btns" slot-scope="btns, num">
                    <a-button class="editable-add-btn" v-for="btn in btns" :key="btn" @click="handel(btn, num)"
                      size="small" style="margin: 5px">
                      {{ btn }}
                    </a-button>
                  </span>
                </a-table>
                <div ref="pagination">
                  <a-pagination v-if="pageshow" :default-current="currentPage" :defaultPageSize="defaultPageSize"
                    :page-size-options="pageSizeOptions" show-size-changer show-quick-jumper :total="total_num"
                    :show-total="(total) => `共 ${total} 条`" @showSizeChange="onShowSizeChange" @change="onPageChange">
                    <template slot="buildOptionText" slot-scope="props">
                      <span>{{ props.value }}条/页</span>
                    </template>
                  </a-pagination>
                </div>
              </div>
              <a-modal v-model="visible" :title="modelTitle" ok-text="确定" @ok="handleOk" cancel-text="取消">
                <div v-if="tuike.length > 0">
                  <a-descriptions layout="vertical" bordered>
                    <a-descriptions-item label="用户昵称">
                      {{ users_nickname }}
                    </a-descriptions-item>
                    <a-descriptions-item label="手机号" :span="2">
                      {{ users_mobile }}
                    </a-descriptions-item>
                    <a-descriptions-item label="邀请人昵称">
                      {{ p_nickname }}
                    </a-descriptions-item>
                    <a-descriptions-item label="邀请人手机号" :span="2">
                      {{ p_mobile }}
                    </a-descriptions-item>
                    <a-descriptions-item label="口令" :span="3">
                      <div v-if="code.length > 0">
                        <div v-for="itp in code" :key="itp.activity_id">
                          {{ itp.activity_title }}：{{ itp.code }}
                        </div>
                      </div>
                      <span v-else>暂无</span>
                      <!-- v-for="itp in code"
                      :key="itp.activity_id" -->
                    </a-descriptions-item>
                    <!-- <a-descriptions-item label="更新时间" :span="2">
                      {{ tuike.updated_at }}
                    </a-descriptions-item> -->
                    <a-descriptions-item v-for="item in tuike" :key="item.platform" :label="item.platform_name"
                      :span="3">
                      <div class="platInfo">
                        <a-badge status="processing" text="审核中" v-if="item.status == 1" />
                        <a-badge status="success" text="审核成功" v-if="item.status == 2" />
                        <a-badge status="error" text="审核失败" v-if="item.status == 3" />
                        <div class="platInfo_all">
                          <div class="platInfo_info">
                            <br />
                            <img :src="item.info.avatar" />
                            <br />
                            昵称：{{ item.info.nickname }} <br />性别：{{
                            item.info.gender == 0
                            ? "未知"
                            : item.info.gender == 1
                            ? "男性"
                            : "女性"
                            }}
                            <br />
                            粉丝数：{{ item.info.fans_count }}
                            <br />
                            主播类型：<span v-for="(item1, index) in item.info.anchor_type" :key="item1">{{ index == 0 ?
                              item1 : "，" + item1 }}
                            </span>
                            <br />
                          </div>
                          <div class="screenshot">
                            <img :src="item.info.screenshot" class="screenshot" v-image-preview />
                          </div>
                        </div>
                      </div>
                    </a-descriptions-item>
                  </a-descriptions>
                </div>
                <div v-else>暂无</div>
              </a-modal>

              <a-modal v-model="visibleGrade" :title="modelTitle" :confirm-loading="confirmLoading" ok-text="确定"
                @ok="handleGradeOk" cancel-text="取消">
                <a-form :form="gradeform" @submit="handleSubmit">
                  <a-form-item label="当前用户等级：" style="
                      display: flex;
                      justify-content: flex-start;
                      flex-dirction: row;
                    ">
                    <span>{{ nowGrade == 1 ? "街头艺人" : ""
                      }}{{ nowGrade == 2 ? "当红明星" : ""
                      }}{{ nowGrade == 3 ? "翻红馆长" : ""
                      }}{{ nowGrade == 4 ? "运营商" : "" }}</span>
                  </a-form-item>
                  <a-form-item label="修改用户等级：">
                    <a-select placeholder="请选择用户等级" style="width: 100%" v-decorator="[
                        'grade',
                        {
                          rules: [
                            { required: true, message: '请选择用户等级!' },
                          ],
                        },
                      ]">
                      <a-select-option value="1" :disabled="nowGrade == 1 ? true : false">
                        街头艺人
                      </a-select-option>
                      <a-select-option value="2" :disabled="nowGrade == 2 ? true : false">
                        当红明星
                      </a-select-option>
                      <a-select-option value="3" :disabled="nowGrade == 3 ? true : false">
                        翻红馆长
                      </a-select-option>
                      <a-select-option value="4" :disabled="nowGrade == 4 ? true : false">
                        运营商
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-form>
              </a-modal>
              <a-modal v-model="visibleInviteCode" :title="modelTitle" :confirm-loading="confirmLoading" ok-text="确定"
                @ok="handleInviteCodeOk" cancel-text="取消">
                <a-form :form="InviteCodeform" @submit="handleSubmit">
                  <a-form-item label="当前邀请码：" style="
                      display: flex;
                      justify-content: flex-start;
                      flex-dirction: row;
                    ">
                    <span>{{ invite_code }}</span>
                  </a-form-item>
                  <a-form-item label="修改邀请码：">
                    <a-input :maxLength="maxLength" placeholder="请输入邀请码" v-decorator="[
                        'invite_code',
                        {
                          rules: [{ required: true, message: '请输入邀请码!' }],
                        },
                      ]" />
                  </a-form-item>
                </a-form>
              </a-modal>
              <a-modal v-model="visibleTbId" :title="modelTitle" :confirm-loading="confirmLoading" ok-text="确定"
                @ok="handleTbIdOk" cancel-text="取消">
                <a-form :form="tbIdform" @submit="handleSubmit">
                  <a-form-item label="淘宝推客ID：">
                    <a-input placeholder="请输入淘宝推客ID" v-decorator="[
                        'tb_id',
                        {
                          rules: [
                            { required: true, message: '请输入淘宝推客ID!' },
                          ],
                        },
                      ]" />
                  </a-form-item>
                </a-form>
              </a-modal>
              <a-modal v-model="visiblejdId" :title="modelTitle" :confirm-loading="confirmLoading" ok-text="确定"
                @ok="handlejdIdOk" cancel-text="取消">
                <a-form :form="jdIdform" @submit="handleSubmit">
                  <a-form-item label="京东推客ID：">
                    <a-input placeholder="请输入京东推客ID" v-decorator="[
                        'jd_id',
                        {
                          rules: [
                            { required: true, message: '请输入京东推客ID!' },
                          ],
                        },
                      ]" />
                  </a-form-item>
                </a-form>
              </a-modal>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
  import Header from "@/components/Header.vue";
  import Sider from "@/components/Sider.vue";
  export default {
    name: "Car",
    data() {
      return {
        form: this.$form.createForm(this, { name: "deviceSearch" }),
        export: 0,
        gradeform: this.$form.createForm(this, { name: "grade" }),
        InviteCodeform: this.$form.createForm(this, { name: "InviteCode" }),
        tbIdform: this.$form.createForm(this, { name: "tbIdform" }),
        jdIdform: this.$form.createForm(this, { name: "jdIdform" }),
        tuike: [],
        columns: [
          {
            title: "用户昵称",
            key: "nickname",
            dataIndex: "nickname",
            align: "center",
          },
          {
            title: "用户头像",
            key: "avatar",
            dataIndex: "avatar",
            scopedSlots: { customRender: "avatar" },
            align: "center",
          },
          {
            title: "用户等级",
            key: "grade",
            dataIndex: "grade",
            scopedSlots: { customRender: "grade" },
            align: "center",
          },
          {
            title: "用户手机号",
            key: "mobile",
            dataIndex: "mobile",
            align: "center",
          },
          {
            title: "邀请人ID",
            key: "pid",
            dataIndex: "pid",
            scopedSlots: { customRender: "pid" },
            align: "center",
          },
          {
            title: "邀请码",
            key: "invite_code",
            dataIndex: "invite_code",
            scopedSlots: { customRender: "invite_code" },
            align: "center",
          },
          {
            title: "邀请权限",
            key: "invitation_enable",
            dataIndex: "invitation_enable",
            scopedSlots: { customRender: "invitation_enable" },
            align: "center",
          },
          {
            title: "用户邀请数",
            key: "inv_nu",
            dataIndex: "inv_nu",
            align: "center",
          },
          {
            title: "京东推客ID",
            key: "jd_id",
            dataIndex: "jd_id",
            width: 130,
            align: "center",
            scopedSlots: { customRender: "jd_id" },
          },
          {
            title: "淘宝推客ID",
            key: "tb_id",
            dataIndex: "tb_id",
            width: 130,
            align: "center",
            scopedSlots: { customRender: "tb_id" },
          },
          {
            title: "支付宝号",
            key: "zfb_pay_mobile",
            dataIndex: "zfb_pay_mobile",
            align: "center",
          },
          {
            title: "收益金额(元)",
            key: "income",
            dataIndex: "income",
            align: "center",
          },
          {
            title: "创建时间",
            key: "created_at",
            dataIndex: "created_at",
            align: "center",
          },
          {
            title: "更新时间",
            key: "updated_at",
            dataIndex: "updated_at",
            align: "center",
          },
          {
            title: "操作",
            key: "btns",
            dataIndex: "btns",
            scopedSlots: { customRender: "btns" },
            align: "center",
            width: 130,
            fixed: "right",
          },
        ],
        maxLength: 6,
        visible: false,
        modelTitle: "",
        fileList: [],
        uploadBtn: true,
        pageshow: false,
        currentPage: 1,
        total_num: 0,
        defaultPageSize: 10,
        pageSizeOptions: ["10", "20", "30", "50", "100"],
        table_h: "",
        userList: [],
        nowGrade: "",
        visibleGrade: false,
        visibleInviteCode: false,
        confirmLoading: false,
        visibleTbId: false,
        visiblejdId: false,
        invite_code: "",
        code: [],
        p_nickname: "",
        p_mobile: "",
      };
    },
    components: {
      Header,
      Sider,
    },
    mounted() {
      window.addEventListener("resize", () => this.changeTableH(), false);
      this.getSearchList({});
    },
    methods: {
      changeTableH() {
        this.table_h =
          this.$refs.box.offsetHeight -
          this.$refs.header.offsetHeight -
          this.$refs.form.offsetHeight -
          this.$refs.pagination.offsetHeight -
          45;
        if (this.table_h <= 250) {
          this.table_h = 400;
        }
      },
      //修改邀请码
      changeInviteCode(num) {
        this.invite_code = num.invite_code;
        this.users_id = num.id;
        this.visibleInviteCode = true;
        this.modelTitle = "修改用户邀请码";
        this.confirmLoading = false;
        this.$nextTick(() => {
          this.InviteCodeform.setFieldsValue({
            invite_code: "",
          });
        });
      },
      handleInviteCodeOk() {
        var that = this;
        that.confirmLoading = true;
        that.InviteCodeform.validateFields((error, values) => {
          if (!error) {
            var reg = /^\d{6}$/;
            if (!reg.test(values.invite_code)) {
              that.$message.error("请输入6位数字");
              that.confirmLoading = false;
              return false;
            }

            var data = values;
            data.users_id = that.users_id;
            that.axios
              .post("/admin/homeUsers/invitationCodeSet", data)
              .then((res) => {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(res)) {
                  that.$message.success("邀请码修改成功");
                  that.form.validateFields((error, values) => {
                    that.getSearchList(values);
                  });
                }

                that.visibleInviteCode = false;
                that.confirmLoading = false;
              });
          } else {
            that.confirmLoading = false;
          }
        });
      },
      //修改用户等级
      changeGrade(num) {
        this.nowGrade = num.grade;
        this.users_id = num.id;
        this.visibleGrade = true;
        this.modelTitle = "修改用户等级";
        this.confirmLoading = false;
        this.$nextTick(() => {
          this.gradeform.setFieldsValue({
            grade: "",
          });
        });
      },
      handleGradeOk() {
        var that = this;
        that.confirmLoading = true;
        that.gradeform.validateFields((error, values) => {
          if (!error) {
            var data = values;
            data.users_id = that.users_id;
            that.axios.post("/admin/homeUsers/gradeSet", data).then((res) => {
              that.$message.loading({
                content: "加载中..",
                key: "load",
                duration: 0.00001,
              });
              if (that.$code(res)) {
                that.$message.success("等级修改成功");
                that.form.validateFields((error, values) => {
                  that.getSearchList(values);
                });
              }

              that.visibleGrade = false;
              that.confirmLoading = false;
            });
          } else {
            that.confirmLoading = false;
          }
        });
      },
      //修改分配淘宝推客Id
      changetbId(num) {
        this.users_id = num.id;
        this.visibleTbId = true;
        this.confirmLoading = false;
        if (num.tb_id) {
          this.modelTitle = "修改淘宝推客ID";
          this.$nextTick(() => {
            this.tbIdform.setFieldsValue({
              tb_id: num.tb_id,
            });
          });
        } else {
          this.modelTitle = "设置淘宝推客ID";
          this.$nextTick(() => {
            this.tbIdform.setFieldsValue({
              tb_id: "",
            });
          });
        }
      },
      handleTbIdOk() {
        var that = this;
        that.confirmLoading = true;
        that.tbIdform.validateFields((error, values) => {
          if (!error) {
            var data = values;
            data.users_id = that.users_id;
            that.axios.post("/admin/homeUsers/TaoBaoPidSet", data).then((res) => {
              that.$message.loading({
                content: "加载中..",
                key: "load",
                duration: 0.00001,
              });
              if (that.$code(res)) {
                that.$message.success("淘宝推客Id设置成功");
                that.form.validateFields((error, values) => {
                  that.getSearchList(values);
                });
              }

              that.visibleTbId = false;
              that.confirmLoading = false;
            });
          } else {
            that.confirmLoading = false;
          }
        });
      },
      //分配京东推客ID
      changejdId(num) {
        this.users_id = num.id;
        this.visiblejdId = true;
        this.confirmLoading = false;
        if (num.jd_id) {
          this.modelTitle = "修改京东推客ID";
          this.$nextTick(() => {
            this.jdIdform.setFieldsValue({
              jd_id: num.jd_id,
            });
          });
        } else {
          this.modelTitle = "设置京东推客ID";
          this.$nextTick(() => {
            this.jdIdform.setFieldsValue({
              jd_id: "",
            });
          });
        }
        // var that=this
        //  var data = {};
        // data.users_id = num.id;
        // that.$confirm({
        //   title: "提示",
        //   content: "确认分配京东推客ID",
        //   okText: "确认",
        //   cancelText: "取消",
        //   onOk() {
        //     that.axios
        //       .get("/admin/jd/userCreateJdPid", { params: data })
        //       .then((res) => {
        //         that.$message.loading({
        //           content: "加载中..",
        //           key: "load",
        //           duration: 0.00001,
        //         });
        //         if (that.$code(res)) {
        //           that.$message.success("分配成功");
        //           that.form.validateFields((error, values) => {
        //             that.getSearchList(values);
        //           });
        //         }
        //       });
        //   },
        // });
      },

      handlejdIdOk() {
        var that = this;
        that.confirmLoading = true;
        that.jdIdform.validateFields((error, values) => {
          if (!error) {
            var data = values;
            data.users_id = that.users_id;
            that.axios
              .post("/admin/homeUsers/JingDongPidSet", data)
              .then((res) => {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(res)) {
                  that.$message.success("京东推客Id设置成功");
                  that.form.validateFields((error, values) => {
                    that.getSearchList(values);
                  });
                }

                that.visiblejdId = false;
                that.confirmLoading = false;
              });
          } else {
            that.confirmLoading = false;
          }
        });
      },
      //获取改变后的页码
      onPageChange(pageNumber) {
        var that = this;
        this.currentPage = pageNumber;
        that.form.validateFields((error, values) => {
          that.getSearchList(values);
        });
      },
      //获取每页条数
      onShowSizeChange(current, pageSize) {
        var that = this;
        this.defaultPageSize = pageSize;
        if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
          that.currentPage = 1;
        }
        that.form.validateFields((error, values) => {
          that.getSearchList(values);
        });
      },
      //获取列表
      getSearchList(data) {
        var that = this;
        data.limit = that.defaultPageSize;
        data.page = that.currentPage;
        that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
        that.pageshow = false;
        that.axios
          .get("/admin/homeUsers/list", {
            params: data,
          })
          .then((res) => {
            that.$message.loading({
              content: "加载中..",
              key: "load",
              duration: 0.00001,
            });
            if (that.$code(res)) {
              var userList = res.data.data;
              for (var i = 0; i < userList.length; i++) {
                userList[i].btns = ["认证信息"];
              }
              that.changeTableH();
              that.userList = userList;
              that.total_num = res.data.count;
              that.currentPage = res.data.currentPage;
            }
          });

        that.$nextTick(() => {
          that.pageshow = true;
        });
      },
      // 操作
      handel(btn, num) {
        var that = this;

        that.users_id = num.id;

        var data = {};
        data.users_id = num.id;
        if (btn == "认证信息") {
          //获取单个用户信息
          that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
          that.axios.post("/admin/homeUsers/one", data).then((t) => {
            that.$message.loading({
              content: "加载中..",
              key: "load",
              duration: 0.00001,
            });
            if (that.$code(t)) {
              that.users_nickname = t.data.data.nickname;
              that.users_mobile = t.data.data.mobile;
              that.p_nickname = t.data.data.p_info.nickname;
              that.p_mobile = t.data.data.p_info.mobile;
              //获取单个用户认证信息
              that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
              that.axios
                .post("/admin/platformAuth/users/one", data)
                .then((res) => {
                  that.$message.loading({
                    content: "加载中..",
                    key: "load",
                    duration: 0.00001,
                  });
                  if (that.$code(res)) {
                    var data1 = {
                      users_id: num.id,
                      status: 2,
                      limit: 10000,
                      page: 1,
                    };
                    that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
                    that.axios
                      .get("/admin/ShareCode/list", {
                        params: data1,
                      })
                      .then((res1) => {
                        that.$message.loading({
                          content: "加载中..",
                          key: "load",
                          duration: 0.00001,
                        });
                        if (that.$code(res1)) {
                          that.tuike = res.data.data;
                          that.code = res1.data.data;
                          that.visible = true;
                          that.modelTitle = "认证信息";
                        }
                      });
                  }
                });
            }
          });
        }
      },
      //修改邀请权限
      changeSetinvitation(nums) {
        var that = this
        var data = {};
        data.users_id = nums.id;
        data.invitation_enable=nums.invitation_enable==1?0:1;
        that.$confirm({
          title: "提示",
          content: "是否确认修改该用户邀请权限？",
          okText: "确认",
          cancelText: "取消",
          onOk() {
            that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
            that.axios
              .post("/admin/homeUsers/invitationEnableSet",data)
              .then((res) => {
                that.$message.loading({
                  content: "加载中..",
                  key: "load",
                  duration: 0.00001,
                });
                if (that.$code(res)) {
                  that.$message.success("操作成功");
                  that.form.validateFields((error, values) => {
                    that.getSearchList(values);
                  });
                }
              });
          },
        });
      },
      //确认
      handleOk() {
        this.visible = false;
        // var that = this;
      },
      //筛选 导出设备
      getExport(e) {
        this.export = e;
        if (this.export == 1) {
          var myObj = {
            method: "get",
            url: "/admin/homeUsers/export",
            fileName: "用户列表.csv",
          };
          this.$exportMethod(myObj);
        }
      },
      handleSubmit(e) {
        if (e) {
          e.preventDefault();
        }
        this.export = 0
        var that = this;
        that.form.validateFields((error, values) => {
          if (that.export == 0) {
            that.currentPage = 1;
            // this.$refs.pagination.internalCurrentPage = this.nowpage;
            that.getSearchList(values);
          }
        });
      },
    },
  };
</script>
<style scoped>
  table img {
    width: 60px;
  }

  .ant-table-wrapper {
    flex: 1;
  }

  .platInfo_all {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .screenshot {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .screenshot img {
    width: 120px;
  }

  .platInfo_info {
    line-height: 30px;
    width: 70%;
  }

  .platInfo_info img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 10px 0;
  }

  .vue-directive-image-previewer {
    z-index: 10000 !important;
  }
</style>